import React from "react";
import { Link } from "gatsby";
import ClubBadge from "./../../../static/img/SSHAFC-BADGE-BLACK-PINK.svg";

const PostLink = ({
  date,
  venue,
  type,
  homeOrAway,
  opponent,
  vsBadge,
  vsBadgeFull,
  scoreUs,
  scoreThem,
  path = "",
  bg,
  detailsBelow,
}) => (
  <div
    className={`card fixture rounded-sm ${
      bg ? bg : "bg-darker-primary"
    } mb-4 shadow`}
  >
    <div className="card-body row align-items-center">
      {detailsBelow && (
        <div className="col-12 small text-center">
          <div className="font-weight-bold">{date}</div>
          <div>{venue}</div>
        </div>
      )}
      {!detailsBelow && (
        <div className="col-md-2 mb-2 text-center text-md-left">
          <div className="font-weight-bold">{date}</div>
          <div>{venue}</div>
          <div>{type}</div>
        </div>
      )}
      <div className={!detailsBelow ? `col-md-8` : `col-md-12`}>
        {homeOrAway === "Away" ? (
          <div className="row align-items-center justify-content-center">
            <div className="col-4 col-md-3 text-right h5 mb-0">{opponent}</div>
            <div className="col-1 col-lg-1 px-2 px-lg-2">
              {vsBadge && (
                <img
                  src={vsBadge}
                  className="vsclubbadge img-fluid"
                  alt={opponent + " badge"}
                />
              )}
            </div>
            {scoreUs || scoreThem ? (
              <div className="col-2 col-md-2 h5 mb-0 p-0 text-center">
                <span className="bg-secondary py-1 px-2 mr-1">{scoreThem}</span>
                <span className="bg-secondary py-1 px-2">{scoreUs}</span>
              </div>
            ) : (
              <div className="col-2 col-md-2 h5 mb-0 text-center">V</div>
            )}
            <div className="col-1 col-lg-1 px-2 px-lg-2">
              {ClubBadge && (
                <img
                  src={ClubBadge}
                  className="vsclubbadge img-fluid text-left"
                  alt={"Seven Hills AFC badge"}
                  height="100"
                  width="100"
                />
              )}
            </div>
            <div className="col-4 col-md-3 text-left h5">Seven Hills AFC</div>
          </div>
        ) : (
          <div className="row align-items-center justify-content-center">
            <div className="col-4 col-md-3 text-right h5 mb-0">
              Seven Hills AFC
            </div>
            <div className="col-1 col-lg-1 px-2 px-lg-2">
              {ClubBadge && (
                <img
                  src={ClubBadge}
                  className="vsclubbadge img-fluid text-left"
                  alt={"Seven Hills AFC badge"}
                  height="100"
                  width="100"
                />
              )}
            </div>

            {scoreUs || scoreThem ? (
              <div className="col-2 col-md-2 h5 mb-0 p-0 text-center">
                <span className="bg-secondary py-1 px-2 mr-1">{scoreUs}</span>
                <span className="bg-secondary py-1 px-2">{scoreThem}</span>
              </div>
            ) : (
              <div className="col-2 col-md-2 h5 mb-0 text-center">V</div>
            )}

            <div className="col-1 col-lg-1 px-2 px-lg-2">
              {vsBadge && (
                <img
                  src={vsBadge}
                  className="vsclubbadge img-fluid"
                  alt={opponent + " badge"}
                  // height={vsBadgeFull.height}
                  // width={vsBadgeFull.width}
                />
              )}
            </div>
            <div className="col-4 col-md-3 text-left h5 mb-0">{opponent}</div>
          </div>
        )}
      </div>
      {path && (
        <>
          {!detailsBelow ? (
            <div className="col-md-2 text-center text-md-right mt-4 mt-md-0 position-unset">
              <Link
                to={path}
                className="stretched-link btn btn-sm btn-outline-secondary"
              >
                View
              </Link>
            </div>
          ) : (
            <Link to={path} className="stretched-link"></Link>
          )}
        </>
      )}
    </div>
  </div>
);
export default PostLink;

import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import FixtureCard from "./fixtureCard";

export default function LatestResults({ ClubBadge }) {
  return (
    <StaticQuery
      query={graphql`
        query LatestResultsQuery {
          allGoogleGamesTrainingFinesSheet(
            filter: { team: { ne: "Training" }, scoreUs: { gt: -1 } }
            sort: { fields: date, order: DESC }
          ) {
            edges {
              node {
                id
                Time: formattedDate
                OpponentName: team
                Conceded: scoreThem
                Goals: scoreUs
                result
                HomeAway: home_Away
                urlPath: gameID
                Location: venue
                type
                OpponentBadge: badge
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="container py-5 mt-5">
          <h2 className="mb-4 text-center">Latest Results</h2>
          {data.allGoogleGamesTrainingFinesSheet.edges.map((edge) => (
            <FixtureCard
              key={edge.node.id}
              date={edge.node.Time}
              venue={edge.node.Location}
              type={edge.node.type}
              homeOrAway={edge.node.HomeAway}
              opponent={edge.node.OpponentName}
              vsBadge={edge.node.OpponentBadge}
              vsBadgeFull={edge.node.OpponentBadge}
              scoreUs={edge.node.Goals}
              scoreThem={edge.node.Conceded}
              path={`/results/${edge.node.urlPath}`}
              ClubBadge={ClubBadge}
            />
          ))}
          <div className="py-5 text-center">
            <Link
              className="btn mx-2 btn-lg btn-secondary d-block d-lg-inline"
              to="/fixtures/"
            >
              View Fixtures
            </Link>
            <Link
              className="btn mx-2 btn-lg btn-outline-secondary mt-3 mt-lg-0 d-block d-lg-inline"
              to="/contact/"
            >
              Book a Game?
            </Link>
          </div>
        </div>
      )}
    />
  );
}

import * as React from "react";
import Layout from "../components/Layout";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";
import LatestResults from "../components/fixtures/LatestResults";
import ClubBadge from "./../img/SSHAFC-badge-no-words.svg";

const NotFoundPage = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={`true`} backgroundColour={`primary`}>
      <Seo
        title={"Results" + meta.sitePageTitleSep + meta.siteTitle}
        slug={meta.siteUrl + "/results/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Results"}
        facebookTitle={"Results"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <LatestResults ClubBadge={ClubBadge} />
    </Layout>
  );
};

export default NotFoundPage;
